.modalbg {
  background-color: #f9f9f9 !important;
  margin: 0px;
}

.dark-theme .modalbg {
  background: #20262d !important;
  margin: 0px;
}

.fc-day {
  background: #ffffff;
  margin-top: 12px;
  border: 14px solid #f9f9f9 !important;
}

.dark-theme .fc-day {
  background: #000000;
  border: 14px solid #20262d !important;
}
.bg-card {
  margin-left: 30px;
  background-color: #f9f9f9;
  padding: 12px;
  overflow: scroll;
}

.dark-theme .bg-card {
  background: #20262d;
  padding: 12px;
  overflow: scroll;
}

#calendar2 > div > div.fc-view-harness.fc-view-harness-active > div > table {
  padding: 8px;
  border: 0 !important;
}

#calendar2 > div > div.fc-view-harness.fc-view-harness-active > div > table > tr,
td,
th {
  border: 0 !important;
}

.fc-col-header thead th {
  padding: 0px !important;
  background: none !important;
  font-weight: 400 !important;
}

@media screen and (max-width: 600px) {
  .fc .fc-toolbar {
    display: flex !important;
  }
}
