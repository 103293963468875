.button1 {
  background-color: #ddd;
  border: none;
  color: black;
  padding: 6px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 16px;
}
.button1 .button1:hover {
  background: #eee;
}

.roundedCorners {
  border-collapse: separate;
  border-spacing: 0;
  width: 90%;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  margin-bottom: 30px;
}

.roundedCorners tr th,
.roundedCorners tr td {
  border-bottom: 1px solid rgba(76, 43, 26, 0.3);
  padding: 0.8rem;
}

.roundedCorners tr th:first-child,
.roundedCorners tr td:first-child {
  width: 40%;
  border-left: 1px solid rgba(76, 43, 26, 0.3);
}

.roundedCorners tr th:last-child,
.roundedCorners tr td:last-child {
  width: 40%;
  border-right: 1px solid rgba(76, 43, 26, 0.3);
}

.roundedCorners tr th {
  border-top: 1px solid rgba(76, 43, 26, 0.3);
  text-align: left;
}

.roundedCorners tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

.roundedCorners tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

.roundedCorners tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

.roundedCorners tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}

.roundedCorners thead:first-child {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #4C2B1A;
}

.roundedCorners tbody > tr > td {
  font-size: 10.8px;
  line-height: 12px;
}

.roundedCorners tbody > tr > td:first-child {
  font-weight: 600;
  font-size: 13px;
  line-height: 14.5px;
}

.roundedCorners thead > tr > th {
  border-bottom: 1px solid #4C2B1A;
}/*# sourceMappingURL=butoon.css.map */